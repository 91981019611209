import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoxyComponent } from './portfolio/boxy/boxy.component';
import { HomeComponent } from './pages/home/home.component';
import {EattouchComponent} from './portfolio/eattouch/eattouch.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'case-study-boxy', component: BoxyComponent },
  { path: 'case-study-eattouch', component: EattouchComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
