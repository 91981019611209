<!-- Intro -->
<section class="main-project-section">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="main-title">
          <strong>UX Case Study</strong>
          <h1>Website redesign for EatTouch</h1>
          <div class="my-role">
            <p><strong>My role:</strong> UX/UI designer</p>
            <p><strong>Year:</strong> 2022 - ongoing</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<img src="assets/img/eattouch/mockup-mobile.jpg" class="img-fluid">

<!-- Intro -->
<section class="main-project-section">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="main-title">
          <p class="brand-summary">Eattouch is a chilean brand with app & web products focused on: Unifying multiple stores to sell different products aiming for low price compared to the market.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Process -->
<section class="project-section first-content-section">
  <div class="container">
    <div class="row mb-3">
      <div class="col-md-8 offset-md-2">
        <h2>How it started</h2>
        <p><small class="light-bg">Note: This product didn't started so much with an outside-in vission. It reflected the information of an old app with many technical and comercial decisions that were already taken. That's why some research couldn't be considered at first moment but during the different iterations (for example information architecture).</small></p>
        <p>We first gathered all the current information we found (Analytics, comments on stores, emails related as support tickets, existing flows, rrss comments and so on) to get a picture of what was going on.
          This project started with the intent to increase sales and first conversations were focused on marketing strategies, after some tests I talked about the poor UI/UX of the product and how it could probably end in a lot of complaints if the focus goes to user acquisition.</p>
          <ul class="cards-list">
            <strong>My key activities:</strong>
            <li>Facilitate/promote methods, tools and strategies for user-centered approach</li>
            <li>LF prototypes, flows and basis for proto persona and customer journey map</li>
            <li>Research related to experience: Usability, Interview, Surveys, A/B, Card sorting</li>
            <li>Interactive Figma prototypes</li>
            <li>Build screens on html5/scss under Angular environment</li>
          </ul>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-8 offset-md-2">
        <h2>Looking towards customers</h2>
        <p>Since the main goal wasn’t a redesign of the site and at first there was not so much room to plan an UX research strategy, I proposed to start with the easiest and fastest approach: A survey, primary based on CSAT and NPS (to be sent once with goal to include at the end of each transaction).</p>
        <p>I made some sample questions related to different areas of the journey and then in alignment with the team, we built the survey.</p>
        <p>The Survey was answered by +200 people and it gave us some information to start with. I used this information for an initial Customer Journey Map:</p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col">
        <img src="assets/img/eattouch/customer-journey-map.png" class="img-fluid">
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h4>What we learnt from this</h4>
        <ul class="cards-list">
          <li>The marketing strategy is bringing <b>new users with high expectations</b> on discounts and variety of products.</li>
          <li><b>Lack of easy-to-find information</b> during the whole journey: Delivery time or address (for each store), discounts involved and total payment. The content was something that could help improve the overall experience.</li>
          <li>The site was complicated to use: <b>many clicks involved during each interaction</b> (modals, alerts and multiple screens involved).</li>
          <li>A support center was a must: if any issues occurred during the purchase, it wasn't easy to contact support or receive help which made turn into <b>a disappointing experience.</b></li>
        </ul>
      </div>
    </div>
  </div>
</section>


<!-- Process -->
<section class="project-section first-content-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h2>Ideation</h2>
        <p>We complemented the insights with a benchmark to see how other related companies/brands are solving related issues (for this I made an excel and recopiled different screenshots from the apps).</p>
        <figure>
          <figcaption>(Part of benchmark for checkout flow)</figcaption>
          <img src="assets/img/eattouch/benchmark.jpg" class="img-fluid">
        </figure>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-8 offset-md-2">
        <p>Aligned with the team after some brainstorming calls, I built some LF prototypes that included screens for the basic checkout process</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <figure>
          <figcaption>(LF Prorotypes - Adding products and going to cart)</figcaption>
          <img src="assets/img/eattouch/lf-prototype-01.jpg" class="img-fluid">
        </figure>
        <figure>
          <figcaption>(LF Prorotypes - Checkout and detail of purchase)</figcaption>
          <img src="assets/img/eattouch/lf-prototype-03.jpg" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
</section>

<!-- Process -->
<section class="project-section light-bg">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>Prototyping</h2>
        <p>I worked in some mid fidelity prototypes to be later tested</p>
        <figure>
          <figcaption>(Home and Store - Mid fidelity before testing)</figcaption>
          <img src="assets/img/eattouch/mid-fid/group-01.png" class="img-fluid">
        </figure>
        <figure>
          <figcaption>(Cart and Checkout - Mid fidelity before testing)</figcaption>
          <img src="assets/img/eattouch/mid-fid/group-02.png" class="img-fluid">
        </figure>
        <figure>
          <figcaption>(Success and Detail of purchase - Mid fidelity before testing)</figcaption>
          <img src="assets/img/eattouch/mid-fid/group-03.png" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
</section>

<!-- Process -->
<section class="project-section light-bg first-content-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h2>Usability testing</h2>
        <div class="group-content">
          <h5>Insight #1</h5>
          <p><strong>Context:</strong> We built screens based on a benchmark from different brands (ie. Rappi, UberEats, Cornershop, PedidosYa) whose flow starts by listing known stores and to buy there one needs to enter a particular store and buy from there (not using the platform itself as a common ecommerce).</p>
          <p><strong>Issue:</strong> Our stores/brands are not as known as the other platforms, so when people came to the site, they saw all the available stores but didn't easily know what they sell because didn't recognize the brand.</p>
          <p><strong>Goal:</strong> Make it easier to understand what products can people buy on EatTouch.</p>
        </div>
        <div class="group-content">
          <h5>Insight #2</h5>
          <p><strong>Issue:</strong> The navigation for general categories or particular categories per store was not clear enough. During the test noone noticed it was on header and instead made comments how a filter would help.</p>
          <p><strong>Goal:</strong> Facilitate access to navigate through categories in the site or in a particular store.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <figure>
          <figcaption>(Design solutions for insights #1 and #2 represented in homepage)</figcaption>
          <img src="assets/img/eattouch/after-test/homepage.png" class="img-fluid">
        </figure>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-8 offset-md-2">
        <p><small>To validate the effectiveness of these modifications, we used Google Optimize for multivariate or A/B tests.</small></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="group-content">
          <h5>Insight #3</h5>
          <p><strong>Issue:</strong> For those who found the category filter, it wasn't easy enough to relate the category with the product they are looking for (or at least with some of the categories).</p>
          <p><strong>Goal:</strong> Validate labeling and information architecture on the site (ongoing).</p>
        </div>
        <p>For this we made an internal open card sorting mainly focused on categorizing stores with the idea to validate through tree test.</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <figure>
          <figcaption>Information architecture with current categories</figcaption>
          <img src="assets/img/eattouch/after-test/information-architecture.jpg" class="img-fluid">
        </figure>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-8 offset-md-2">
        <p>I created a script for tree testing on Optimal Workshop with the following tasks:</p>
        <ul>
          <li>You're having a BBQ on friday night and want to buy some meat for grilling. Where would you look for it?</li>
          <li>You also want to buy some drinks for the night. Where would you look for them?</li>
          <li>Let's buy some peanuts, almonds and some other nuts, go to the place you think you can find them.</li>
          <li>You bought all that and now can't remember which address did you choose for the purchase. Where would you look for that information?</li>
          <li>You now have some doubts about your purchase and need some assistance. Where would you go?</li>
        </ul>
        <p><small>(This test has not yet been done)</small></p>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="group-content">
          <h5>Insight #4</h5>
          <p><strong>Issue:</strong> The main CTA on checkout was positionated fixed on bottom area (prioritizing mobile) but this on desktop wasn't so much perceived as a button and most people had troubles to find it (This was tested on mid-fidelity prototypes and didn't seem a problem, but on the site it resulted mostly on a stopper).</p>
          <p><strong>Goal:</strong> Make it easier to understand the main action button on checkout page</p>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <figure>
          <figcaption>Some of the mid-fid prototypes are on <a href="https://www.figma.com/file/qoC0FGRHdKnYSCxF8aJPYS/EatTouch-Web---202210?node-id=301%3A4478" target="_blank">Figma</a></figcaption>
          <img src="assets/img/eattouch/after-test/group-prototypes.png" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
</section>

<!-- Process -->
<section class="project-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h2>Ongoing</h2>
        <p>This product still in progress. We still learning from users and data to test (or solve) new ideas each sprint. I explained some parts of the cycles but it has iterated during the whole 2022 and has passed through different tests and iterations related to ux, cx and different areas. I focused this case study to explain some of my interventions instead of the whole results gathered with the team.</p>
      </div>
    </div>
  </div>
</section>

<img src="assets/img/eattouch/mockup-desktop.jpg" class="img-fluid">