<!-- Intro -->
<section class="main-project-section">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="main-title text-center">
          <h1>UX Case Study — Boxy</h1>
          <h3>by <a href="https://cl.linkedin.com/in/aphoomzhah" target="_blank">Julio Yanes</a></h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img src="../../../assets/img/boxy/cover-img.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</section>

<!-- The Project -->
<section class="project-section text-center mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h2>The Project</h2>
        <p>Boxy intends to make it easier for people to replace products of everyday life from their own comfort while saving time and money. It offers plans with a monthly based subscription under different categories</p>
      </div>
    </div>
  </div>
</section>

<!-- The Process -->
<section class="process-section">
  <div class="container">
    <div class="row">
      <div class="col-md mb-5">
        <h2>The process</h2>
        <p>We applied Lean UX / Design Thinking process to deliver the product. Started gathering insights from interviews, surveys and analytical research.</p>
        <div class="design-thinking-steps mt-5">
          <a class="active">Empathize</a>
          <a>Define</a>
          <a>Ideate</a>
          <a>Prototype</a>
          <a>Test</a>
          <a>Implement</a>
        </div>
        <div class="my-role">
          <p><strong>My role:</strong> UX designer / Frontend</p>
          <p><strong>Year:</strong> 2020</p>
        </div>
      </div>
      <div class="col-md">
        <img src="../../../assets/img/boxy/intro-img.png" class="img-fluid">
      </div>
    </div>
  </div>
</section>

<!-- The research -->
<section class="research-section mb-5">
  <div class="container">
    <div class="row mb-3">
      <div class="col">
        <div class="design-thinking-steps mb-5">
          <a>Empathize</a>
          <a class="active">Define</a>
          <a>Ideate</a>
          <a>Prototype</a>
          <a>Test</a>
          <a>Implement</a>
        </div>
        <!-- <h4 class="current-step mb-5">Define</h4> -->
      </div>
    </div>
    <div class="row">
      <div class="col-sm mb-5">
        <h3>Personas</h3>
        <p class="sm-paragraph">After gathering feedback, we made some personas who (in a very sumarized way) looked like this:</p>
        <div class="card-item">
          <div class="main-info">
            <h5>Tanya, 34 años</h5>
            <p class="quote-text">Me interesa ahorrar tiempo en transacciones cotidianas.</p>
            <strong>Intereses:</strong>
            <ul>
              <li>Realizar compras y suscripciones online.</li>
              <li>Mantenerse al día en la tecnología.</li>
            </ul>
          </div>
        </div>
        <div class="card-item">
          <div class="main-info">
            <h5>Juan, 42 años</h5>
            <p class="quote-text">Me gusta conseguir promociones de productos o actividades.</p>
            <strong>Intereses:</strong>
            <ul>
              <li>Compartir tiempo con su familia y mascotas.</li>
              <li>Utilizar internet como distracción.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm mb-5">
        <h3>Hypothesis</h3>
        <p class="sm-paragraph">I defined a table of hypothesis and discussed  it with the team. After some brain storming seassons, we ended up with some user outcome based hypothesis.</p>
        <div class="card-item">
          <div class="main-info">
            <p>Creemos que se puede lograr [usuarios suscritos a uno o más planes] si [Tanya] logra <strong>[Ahorrar tiempo en la adquisición de productos recurrentes]</strong> con [suscripción de plan, simples pasos]</p>
          </div>
        </div>
        <div class="card-item">
          <div class="main-info">
            <p>Creemos que se puede lograr [interés en el producto] si [Juan] logra <strong>[Conseguir productos de interés a precio conveniente]</strong> con [categorización de planes / ofertas destacadas]</p>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <h3>Arquitechture</h3>
        <p class="sm-paragraph">With some goals and features in mind, I defined the arquitechture of the site, along with some flows. Which again, revised and completed with the team.</p>
        <img class="card-img" src="../../../assets/img/boxy/flow.jpg">
      </div>
    </div>
  </div>
</section>

<!-- The Prototypes -->
<section class="prototypes-section mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 text-center mb-5">
        <!-- <h4 class="current-step">Ideate</h4> -->
        <div class="design-thinking-steps mb-3">
          <a>Empathize</a>
          <a>Define</a>
          <a class="active">Ideate</a>
          <a>Prototype</a>
          <a>Test</a>
          <a>Implement</a>
        </div>
        <h2>Prototyping</h2>
        <strong>Flows / Low fidelity</strong>
        <p>With all the previous research, I started making low fidelity prototypes based on the defined flows. We made some design seassons here so everyone could apport their own ideas on the product.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <img src="../../../assets/img/boxy/lf-prototypes.png" class="img-fluid">
      </div>
    </div>
  </div>
</section>

<!-- Wireframing -->
<section class="wireframing-section">
  <div class="container">
    <div class="row mb-3">
      <div class="col-md-6 offset-md-3 text-center">
        <div class="design-thinking-steps mb-3">
          <a>Empathize</a>
          <a>Define</a>
          <a>Ideate</a>
          <a class="active">Prototype</a>
          <a>Test</a>
          <a>Implement</a>
        </div>
        <!-- <h4 class="current-step">Design</h4> -->
        <h2>Wireframing</h2>
        <p>After testing the low fidelity prototypes, we understood our checkout process could be simplified, we got ride of the "steps" concept and concentrated on one page with the lesser possible fields on form.</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img src="../../../assets/img/boxy/checkout-prototypes.png" class="img-fluid">
      </div>
    </div>
  </div>
</section>

<!--Testing -->
<section class="testing-section">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-6 offset-md-3">
        <div class="design-thinking-steps mb-3">
          <a>Empathize</a>
          <a>Define</a>
          <a>Ideate</a>
          <a>Prototype</a>
          <a class="active">Test</a>
          <a>Implement</a>
        </div>
        <!-- <h4 class="current-step">Test</h4> -->
        <h2>Validation</h2>
        <p>During the whole process I ran different kind of tests (usability, interviews, heatmaps, a/b). From these, we found:</p>             
      </div>
    </div>
    <div class="row validations-area mt-3">
      <div class="col-md">
        <div class="card-item">
          <div class="main-info">
            <p>Our <strong>labeling for categories</strong> wasn't clear enough: we used to call name them as "doglover", "catlover" and so on... which we later changed to "perros", "gatos" (changing to spanish, and then moving the "lover" sufix to single plans, not categories).</p>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card-item">
          <div class="main-info">
            <p>Our <strong>checkout process</strong> was initially made with 3 - 4 steps (intending to simplify the content of each view, but instead it was a bit more complex than it could be). We reduced the required fields and compact it just on 1 or 2 screens.</p>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card-item">
          <div class="main-info">
            <p>Our <strong>personas</strong> were first defined based on categories, later we found it wasn't the best way to filter it, and updated them according to what they wanted to accomplish on the site).</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Frontend-->
<section class="frontend-section">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-6 offset-md-3">
        <div class="design-thinking-steps mb-3">
          <a>Empathize</a>
          <a>Define</a>
          <a>Ideate</a>
          <a>Prototype</a>
          <a>Test</a>
          <a class="active">Implement</a>
        </div>
        <!-- <h4 class="current-step">Implement</h4> -->
        <h2>Frontend</h2>
        <p>This is a work in progress, in order to keep testing our product, we made it as a mobile oriented site under WordPress Platform. For which I made frontend under Html5, Scss and some JavaScript.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <img src="../../../assets/img/boxy/mockup.png" class="img-fluid">
      </div>
    </div>
  </div>
</section>